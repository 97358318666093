define('ember-simple-auth/initializers/setup-session-restoration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = setupSessionRestoration;
  var getOwner = Ember.getOwner;
  function setupSessionRestoration(registry) {
    var ApplicationRoute = registry.resolveRegistration ? registry.resolveRegistration('route:application') : registry.resolve('route:application');

    ApplicationRoute.reopen({
      init: function init() {
        this._super.apply(this, arguments);

        var originalBeforeModel = this.beforeModel;
        this.beforeModel = function () {
          var _this = this,
              _arguments = arguments;

          var session = getOwner(this).lookup('session:main');

          return session.restore().then(function () {
            return originalBeforeModel.apply(_this, _arguments);
          }, function () {
            return originalBeforeModel.apply(_this, _arguments);
          });
        };
      }
    });
  }
});