define('ember-cli-sanitize-html/utils/sanitize-html', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var sanitize = function sanitize() {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var options = arguments[1];

    return typeof window === 'undefined' ? value : window.sanitizeHtml(value, options);
  };

  exports.default = sanitize;
});