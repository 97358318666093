define('ember-cli-owl-carousel2/components/owl-carousel2', ['exports', 'ember-cli-owl-carousel2/templates/components/owl-carousel2'], function (exports, _owlCarousel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _owlCarousel.default,
    carousel: null,
    classNames: ['owl-carousel'],
    classNameBindings: ['theme:owl-theme'],
    theme: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var attrs = Ember.assign(this.attrs, {
        navText: typeof this.attrs.navText === 'string' && JSON.parse(this.attrs.navText),
        responsive: typeof this.attrs.responsive === 'string' && JSON.parse(this.attrs.responsive)
      });
      var carousel = Ember.$(this.element).owlCarousel(attrs);
      this.set('carousel', carousel);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$(this.element).owlCarousel('destroy');
    }
  });
});