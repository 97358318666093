define('ember-cli-sanitize-html/components/sanitize-html', ['exports', 'ember-cli-sanitize-html/utils/sanitize-html', 'ember-cli-sanitize-html/templates/components/sanitize-html'], function (exports, _sanitizeHtml, _sanitizeHtml2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _sanitizeHtml2.default,

    raw: false,
    value: undefined,
    options: undefined,
    classNames: ['sanitized-value'],

    didReceiveAttrs: function didReceiveAttrs() {
      this.get('sanitizedValue');
    },


    sanitizedValue: computed('value', 'options', function () {
      var value = this.get('value');
      var options = this.get('options');
      return (0, _sanitizeHtml.default)(value, options);
    })
  });
});